export default[
  {
    key: 'name_file',
    label: 'Name',
  },
  {
    key:'size',
    label:'Size',
  },
  {
    key:'created_at',
    label:'Upload At',
  }
]
<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refGridRealtorCR'].refresh()"
    >
      <template #table>
        <b-table
          ref="refGridRealtorCR"
          no-border-collapse
          class="position-relative"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="search"
          :sort-by.sync="sortByChange"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <b-col>
              <b-row>
                <router-link
                  target="_blank"
                  :to="
                    '/specialistdigital/clients/account/' + data.item.id_account
                  "
                >
                  {{ data.item.client_name }}
                  <feather-icon
                    :icon="
                      data.item.status_account === 2
                        ? 'TriangleIcon'
                        : 'CircleIcon'
                    "
                    size="13"
                    :fill="colorStatus(data.item.status_account)"
                    :style="{ color: colorStatus(data.item.status_account) }"
                  />
                </router-link>
              </b-row>
              <b-row>
                <div>{{ data.item.mobile }}</div>
              </b-row>
              <b-row>
                <div>{{ data.item.account }}</div>
              </b-row>
              <b-row>
                <badge-risky-clients
                  :id-account="data.item.id_account"
                  :risk-level="data.item.risk_level"
                  :risk-description="data.item.risk_description"
                  :risk-type="data.item.risk_type"
                />
              </b-row>
            </b-col>
          </template>
          <template #cell(create_name_user)="data">
            <b-col>
              <b-row>{{ data.item.create_name_user }}</b-row>
              <b-row>{{ data.item.created_at | myGlobalWithHour }}</b-row>
            </b-col>
          </template>
          <template #cell(date_completed)="data">
            <div>
              <span>{{ data.item.recomendation_name_user }}</span
              ><br />
              <span>{{ data.item.date_completed | myGlobalWithHour }}</span>
            </div>
          </template>
          <template #cell(files)="data">
            <center>
              <feather-icon
                icon="FolderIcon"
                class="cursor-pointer"
                style="color: var(--warning)"
                size="20"
                @click="openModalTracking(data.item)"
              />
            </center>
          </template>
          <template #cell(auth)="data">
            <center>
              <a target="_blank" @click="exportToPdf(data.item.id_account)">
                <amg-icon icon="FilePdfIcon" size="20" />
              </a>
            </center>
          </template>
          <template #cell(pdf)="data">
            <center>
              <feather-icon
                :icon="data.item.hasFiles ? 'EyeIcon' : 'UploadIcon'"
                :class="['cursor-pointer', data.item.hasFiles ? 'text-success' : 'text-primary']"
                size="20"
                @click="openModalUploadCr(data.item)"
              />
            </center>
          </template>
          <template #cell(cr)="data">
            <center :title="data.item.client_name">
              <router-link
                v-if="data.item.state == 1"
                :to="{
                  name: 'specialistdigital-lead-car',
                  params: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                    accountId: data.item.id_account,
                    modul: 25,
                  },
                }"
                target="_blank"
                style="cursor: pointer"
              >
                <amg-icon
                  v-if="data.item.process == null"
                  icon="FileChartIcon"
                  style="color: black"
                  size="20"
                />
                <amg-icon
                  v-if="data.item.process == 1"
                  icon="FileChartIcon"
                  style="color: green"
                  size="20"
                />
              </router-link>
              <router-link
                v-if="data.item.state == 0"
                :to="{
                  name: 'specialistdigital-lead-car',
                  params: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                    accountId: data.item.id_account,
                    modul: 25,
                  },
                }"
                target="_blank"
                style="cursor: pointer"
              >
                <amg-icon
                  v-if="data.item.process == 1"
                  icon="FileChartIcon"
                  style="color: green"
                  size="20"
                />
                <amg-icon
                  v-else
                  icon="FileChartIcon"
                  style="color: orange"
                  size="20"
                />
              </router-link>
              <span
                v-if="data.item.process == 1 && data.item.state == 0"
                style="color: orange"
                title="Report obtained manually"
              >
                <i class="fas fa-exclamation-triangle" />
              </span>
            </center>
          </template>
          <template #cell(rounds)="data">
            <div
              v-if="data.item.ncr_id"
              class="text-center cursor-pointer"
              @click="openModalRounds(data.item)"
            >
              <b-badge v-if="data.item.total_rounds" variant="primary">
                {{ data.item.total_rounds }}
              </b-badge>
              <b-badge v-else variant="primary"> 0 </b-badge>
            </div>

            <div v-else class="text-center cursor-pointer">
              <b-badge
                v-b-tooltip.hover.left="
                  'You have to generate the credit report'
                "
                variant="primary"
              >
                0
              </b-badge>
            </div>
          </template>
          <template #cell(work_plan)="data">
            <div class="d-flex justify-content-center">
              <b-button
                variant="outline"
                class="d-flex justify-content-center cursor-pointer p-0"
                :disabled="!data.item.process"
                @click="openWorkPlanModal(data.item)"
              >
                <feather-icon
                  v-b-tooltip.hover.left="
                    data.item.process == 1 && data.item.id_work_plan
                      ? 'SHOW'
                      : 'ADD'
                  "
                  :icon="
                    data.item.process == 1 && data.item.id_work_plan
                      ? 'EyeIcon'
                      : 'PlusCircleIcon'
                  "
                  :class="
                    data.item.process == 1 && data.item.id_work_plan
                      ? 'text-success'
                      : 'text-primary'
                  "
                  size="20"
                  style="cursor: pointer"
                />
              </b-button>
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
               <b-button
              pill
              variant="success"
              class="text-center widthClients text-light"
              :disabled="data.item.hasFiles == 0"
              @click="openModalDoneRealtor(data.item)"
            >
              <!-- :disabled="
                !(
                  data.item.process == 1 &&
                  data.item.id_work_plan &&
                  data.item.total_rounds > 0
                )
              " -->
              <feather-icon
                icon="CheckIcon"
                size="15"
                :class="isDarkSkin ? 'text-white mr-1' : 'text-white mr-1'"
              />DONE
            </b-button>
            </div>
           
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-tracking
      v-if="activeModalTracking"
      :client-data="client"
      @close="closeModalTracking"
      @refreshTable="$refs['refGridRealtorCR'].refresh()"
    />

    <modal-done-realtor
      v-if="isModalDoneRealtor"
      :client-data="client"
      @hideModal="closeModalDoneRealtor"
      @updateTable="$refs['refGridRealtorCR'].refresh()"
    />

    <upload-realtor-cr
      v-if="activeModalUploadCr"
      :client-data="client"
      @close="closeModalUploadCr"
      @changeStatusRead="changeStatusRead($event)"
      @refreshTable="$refs['refGridRealtorCR'].refresh()"
    />

    <modal-add-work-plan
      v-if="workPlanController"
      :id-work-plan="workPlanData.id_work_plan"
      :id-ncr="workPlanData.ncr_id"
      :client-name="workPlanData.client_name"
      :client_id="workPlanData.id_account"
      :account="workPlanData.account"
      @reload="$refs['refGridRealtorCR'].refresh()"
      @close="workPlanController = false"
    />

    <add-round-modal
      v-if="addRoundModal"
      :data-round="itemClient"
      :validate-modal="0"
      @refresh="$refs['refGridRealtorCR'].refresh()"
      @close="addRoundModal = false"
    />
  </div>
</template>

<script>
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
import dataFields from "@/views/specialist-digital/views/cr-realtor/components/fields.data.js";
import dataFilters from "@/views/specialist-digital/views/cr-realtor/components/filters.data.js";
import IconStatusAccount from "@/views/commons/components/ncr-realtor/views/components/icons/IconStatusAccount.vue";
import ModalTracking from "@/views/commons/components/ncr-realtor/views/components/modals/ModalTrackingDigitalSpecialist.vue";
import UploadRealtorCr from "@/views/specialist-digital/views/cr-realtor/components/AddFileDragAndDrop.vue";
import ModalDoneRealtor from "@/views/specialist-digital/views/cr-realtor/components/ModalDoneRealtor.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import { mapGetters } from "vuex";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";

export default {
  components: {
    IconStatusAccount,
    ModalTracking,
    UploadRealtorCr,
    ModalAddWorkPlan,
    ModalDoneRealtor,
    AddRoundModal,
    BadgeRiskyClients,
  },
  data() {
    return {
      startPage: 0,
      toPage: 0,
      totalRows: 1,
      sortBy: "created_at",
      sortDesc: false,
      perPageOptions: [10, 25, 50, 100],
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      itemClient: {
        id: null,
        account: "",
        clientName: "",
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name of clients...",
        model: "",
      },
      isBusy: false,
      addRoundModal: false,
      fields: dataFields,
      items: [],
      filters: dataFilters,
      activeModalTracking: false,
      activeModalUploadCr: false,
      client: {
        name: "",
        account: "",
        realtor_id: null,
        account_id: "",
        read_status: null,
        // Pendin Process CR
        id: 0,
        lead_id: 0,
      },
      workPlanData: {},
      workPlanController: false,
      isModalDoneRealtor: false,
    };
  },
  computed: {
    visibleFields() {
      this.fields[2].visible = this.$route.meta.statusCR == 1;
      this.fields[6].visible = this.$route.meta.statusCR == 0;
      return this.fields.filter((item) => item.visible);
    },
    isCompleted() {
      return !!this.$route.meta.statusCR;
    },
    sortByChange() {
      return this.$route.meta.statusCR ? "date_completed" : "created_at";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId(){
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    openWorkPlanModal(data) {
      this.workPlanData = data;
      this.workPlanController = true;
    },
    openModalRounds(data) {
      this.itemClient = {
        id: data.ncr_id,
        idAccount: data.id_account,
        account: data.account,
        clientName: data.client_name,
        isDirect: { id: data.id, title: data.title, format: data.format },
      };
      this.addRoundModal = true;
    },
    colorStatus(status) {
      let selected;
      switch (status) {
        case 1:
          selected = "#00CC00"; // green
          break;
        case 2:
          selected = "yellow";
          break;
        case 3:
          selected = "blue";
          break;
        case 4:
          selected = "red";
          break;
        case 5:
          selected = "red";
          break;
        case 6:
          selected = "red";
          break;
      }
      return selected;
    },
    openModalTracking(row) {
      this.addPreloader();
      this.client.name = row.client_name;
      this.client.account = row.account;
      this.client.realtor_id = row.nc_id;
      this.client.account_id = row.id_account;
      this.client.read_status = row.read;

      this.activeModalTracking = true;
    },
    openModalDoneRealtor(row) {
      this.addPreloader();
      this.client.name = row.client_name;
      this.client.lead_name = row.client_name;
      this.client.account = row.account;
      this.client.realtor_id = row.nc_id;
      this.client.account_id = row.id_account;
      this.client.read_status = row.read;

      // Pendin Process CR
      this.client.id = row.ncr_id;
      this.client.lead_id = row.lead_id;

      this.isModalDoneRealtor = true;
    },
    openModalUploadCr(row) {
      this.addPreloader();
      this.client.name = row.client_name;
      this.client.account = row.account;
      this.client.realtor_id = row.nc_id;
      this.client.account_id = row.id_account;
      this.client.read_status = row.read;

      this.activeModalUploadCr = true;
    },
    closeModalTracking() {
      this.activeModalTracking = false;
    },
    closeModalDoneRealtor() {
      this.isModalDoneRealtor = false;
    },
    closeModalUploadCr() {
      this.activeModalUploadCr = false;
    },
    async exportToPdf(account) {
      this.addPreloader();
      try {
        const data = await NcrRealtorService.generateBorrowerSignaturePdf({
          account,
        });
        if (data.status === 200) {
          await this.forceFileDownloadPdf(data.data);
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async changeStatusRead(id) {
      const parameters = {
        idrealtor: id,
        readd: 1,
      };
      if (this.moduleId != 11 && this.isCompleted) {
        const result = await NcrRealtorService.changeStatusRead({
          ...parameters,
        });

        result ? this.$refs.refGridRealtorCR.refresh() : this.showErrorSwal();
      }
    },
    async search(ctx) {
      try {
        let orderBy = 1;
        if (ctx.sortBy === "create_name_user") {
          orderBy = 1;
        } else if (ctx.sortBy === "date_completed") {
          orderBy = 2;
        }
        const params = {
          advisor:
            this.currentUser.role_id === 3 || this.currentUser.role_id === 15
              ? this.currentUser.user_id
              : null,
          from: this.filters[0].model,
          module: this.moduleId,
          name_text: this.filterPrincipal.model,
          page: ctx.currentPage,
          perpage: ctx.perPage,
          order: ctx.sortDesc == 1 ? "desc" : "asc",
          orderby: orderBy,
          role_id: this.currentUser.role_id,
          selectmoduleid: null,
          status: null,
          statusclient: null,
          statuss: this.$route.meta.statusCR,
          to: this.filters[1].model,
          is_digital: 2,
        };
        const data = await NcrRealtorService.getNcrRealtor(params);
        this.items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return this.items || [];
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
</script>

<style>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}
.blue {
  color: #0066ff;
  border-color: #00cc00;
  background: #0066ff;
}
.red {
  color: red;
  border-color: red;
  background: red;
}
.yellow {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #ffc107;
}
</style>

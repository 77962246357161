<template>
  <b-modal
    size="lg"
    v-model="isVisible"
    modal-class="modal-primary"
    title="Files of Agent"
    title-tag="h3"
    @hidden="openMe(false)"
    :hide-footer="true"
  >
    <account-client-header :account="client.account" :client="client.name" />
    <b-row>
      <b-col>
        <h5>Observation</h5>
        <b-form-textarea v-model="obs" rows="5" disabled />
      </b-col>
      <b-col>
        <h5>Files of Agent</h5>
        <b-table
          :items="myData.advisor"
          :fields="fieldsTable"
          class="mt-1"
          show-empty
          small
        >
          <template #cell(name_file)="data">
            <a :href="data.item.route" target="_blank">
              {{ data.item.name_file }}
            </a>
          </template>
          <template #cell(created_at)="data">
            <span v-if="data.value">
              {{ data.item.created_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <br />
  </b-modal>
</template>
<script>
//components
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
//services
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
//data
import FilesFields from "@/views/commons/components/ncr-realtor/views/data/modal-tracking-fields.js";
//store
import { mapGetters } from "vuex";

export default {
  components: {
    AccountClientHeader,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
      file: null,
      myData: {},
      fieldsTable: null,
      obs: "",
      recomendation: "",
    };
  },
  methods: {
    async sendData() {
      const parameters = {
        file_name: this.file ? this.file.name : "",
        file_size: this.file ? this.file.size : "",
        file_type: this.file ? this.file.type : "",
        fileapproved: this.file ? this.file.fileapproved : "",
        id_account: this.client.account_id,
        idmodul: this.moduleId,
        idrealtor: this.client.realtor_id,
        observation: this.recomendation,
        user_id: this.currentUser.user_id,
      };

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const data = await NcrRealtorService.addRecomendationNcrRealtor({
          ...parameters,
        });
        this.removePreloader();
        if (data) {
          this.showSuccessSwal();
          this.$emit("refreshTable");
          this.openMe(false);
        } else {
          this.showErrorSwal();
        }
      }
    },
    //esta funcion es para agreagr el "fileapproved"
    uploadFile(e) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = (e) => {
        this.file.fileapproved = e.target.result;
      };
    },
    async start() {
      this.fieldsTable = FilesFields;
      const data = await NcrRealtorService.getDataRealtor(
        this.clientData.realtor_id
      );

      this.myData = { ...data };

      this.obs = this.myData.general.obs;
      this.recomendation = this.myData.general.recomendation;
      this.client.name = this.clientData.name;
      this.client.account = this.clientData.account;
      this.client.realtor_id = this.clientData.realtor_id;
      this.client.account_id = this.clientData.account_id;
      this.client.read_status = this.clientData.read_status;
    },
    openMe(open) {
      this.isVisible = open;
      if (!open) this.$emit("close");
    },
  },
  computed: {
    notSpecialist() {
      return this.moduleId != 25 ? true : false;
    },
    isCompleted() {
      return this.$route.meta.statusCR ? true : false;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.start();
    this.removePreloader();
    this.openMe(true);
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refGridRealtorCR'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refGridRealtorCR",staticClass:"position-relative",attrs:{"no-border-collapse":"","fields":_vm.visibleFields,"show-empty":"","no-provider-filtering":"","sticky-header":"50vh","primary-key":"id","responsive":"sm","items":_vm.search,"sort-by":_vm.sortByChange,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortByChange=$event},"update:sort-by":function($event){_vm.sortByChange=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [_c('b-col',[_c('b-row',[_c('router-link',{attrs:{"target":"_blank","to":'/specialistdigital/clients/account/' + data.item.id_account}},[_vm._v(" "+_vm._s(data.item.client_name)+" "),_c('feather-icon',{style:({ color: _vm.colorStatus(data.item.status_account) }),attrs:{"icon":data.item.status_account === 2
                      ? 'TriangleIcon'
                      : 'CircleIcon',"size":"13","fill":_vm.colorStatus(data.item.status_account)}})],1)],1),_c('b-row',[_c('div',[_vm._v(_vm._s(data.item.mobile))])]),_c('b-row',[_c('div',[_vm._v(_vm._s(data.item.account))])]),_c('b-row',[_c('badge-risky-clients',{attrs:{"id-account":data.item.id_account,"risk-level":data.item.risk_level,"risk-description":data.item.risk_description,"risk-type":data.item.risk_type}})],1)],1)]}},{key:"cell(create_name_user)",fn:function(data){return [_c('b-col',[_c('b-row',[_vm._v(_vm._s(data.item.create_name_user))]),_c('b-row',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at)))])],1)]}},{key:"cell(date_completed)",fn:function(data){return [_c('div',[_c('span',[_vm._v(_vm._s(data.item.recomendation_name_user))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(data.item.date_completed)))])])]}},{key:"cell(files)",fn:function(data){return [_c('center',[_c('feather-icon',{staticClass:"cursor-pointer",staticStyle:{"color":"var(--warning)"},attrs:{"icon":"FolderIcon","size":"20"},on:{"click":function($event){return _vm.openModalTracking(data.item)}}})],1)]}},{key:"cell(auth)",fn:function(data){return [_c('center',[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.exportToPdf(data.item.id_account)}}},[_c('amg-icon',{attrs:{"icon":"FilePdfIcon","size":"20"}})],1)])]}},{key:"cell(pdf)",fn:function(data){return [_c('center',[_c('feather-icon',{class:['cursor-pointer', data.item.hasFiles ? 'text-success' : 'text-primary'],attrs:{"icon":data.item.hasFiles ? 'EyeIcon' : 'UploadIcon',"size":"20"},on:{"click":function($event){return _vm.openModalUploadCr(data.item)}}})],1)]}},{key:"cell(cr)",fn:function(data){return [_c('center',{attrs:{"title":data.item.client_name}},[(data.item.state == 1)?_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":{
                name: 'specialistdigital-lead-car',
                params: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                  accountId: data.item.id_account,
                  modul: 25,
                },
              },"target":"_blank"}},[(data.item.process == null)?_c('amg-icon',{staticStyle:{"color":"black"},attrs:{"icon":"FileChartIcon","size":"20"}}):_vm._e(),(data.item.process == 1)?_c('amg-icon',{staticStyle:{"color":"green"},attrs:{"icon":"FileChartIcon","size":"20"}}):_vm._e()],1):_vm._e(),(data.item.state == 0)?_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":{
                name: 'specialistdigital-lead-car',
                params: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                  accountId: data.item.id_account,
                  modul: 25,
                },
              },"target":"_blank"}},[(data.item.process == 1)?_c('amg-icon',{staticStyle:{"color":"green"},attrs:{"icon":"FileChartIcon","size":"20"}}):_c('amg-icon',{staticStyle:{"color":"orange"},attrs:{"icon":"FileChartIcon","size":"20"}})],1):_vm._e(),(data.item.process == 1 && data.item.state == 0)?_c('span',{staticStyle:{"color":"orange"},attrs:{"title":"Report obtained manually"}},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()],1)]}},{key:"cell(rounds)",fn:function(data){return [(data.item.ncr_id)?_c('div',{staticClass:"text-center cursor-pointer",on:{"click":function($event){return _vm.openModalRounds(data.item)}}},[(data.item.total_rounds)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(data.item.total_rounds)+" ")]):_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" 0 ")])],1):_c('div',{staticClass:"text-center cursor-pointer"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(
                'You have to generate the credit report'
              ),expression:"\n                'You have to generate the credit report'\n              ",modifiers:{"hover":true,"left":true}}],attrs:{"variant":"primary"}},[_vm._v(" 0 ")])],1)]}},{key:"cell(work_plan)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"d-flex justify-content-center cursor-pointer p-0",attrs:{"variant":"outline","disabled":!data.item.process},on:{"click":function($event){return _vm.openWorkPlanModal(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(
                  data.item.process == 1 && data.item.id_work_plan
                    ? 'SHOW'
                    : 'ADD'
                ),expression:"\n                  data.item.process == 1 && data.item.id_work_plan\n                    ? 'SHOW'\n                    : 'ADD'\n                ",modifiers:{"hover":true,"left":true}}],class:data.item.process == 1 && data.item.id_work_plan
                    ? 'text-success'
                    : 'text-primary',staticStyle:{"cursor":"pointer"},attrs:{"icon":data.item.process == 1 && data.item.id_work_plan
                    ? 'EyeIcon'
                    : 'PlusCircleIcon',"size":"20"}})],1)],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"text-center widthClients text-light",attrs:{"pill":"","variant":"success","disabled":data.item.hasFiles == 0},on:{"click":function($event){return _vm.openModalDoneRealtor(data.item)}}},[_c('feather-icon',{class:_vm.isDarkSkin ? 'text-white mr-1' : 'text-white mr-1',attrs:{"icon":"CheckIcon","size":"15"}}),_vm._v("DONE ")],1)],1)]}}])})]},proxy:true}])}),(_vm.activeModalTracking)?_c('modal-tracking',{attrs:{"client-data":_vm.client},on:{"close":_vm.closeModalTracking,"refreshTable":function($event){return _vm.$refs['refGridRealtorCR'].refresh()}}}):_vm._e(),(_vm.isModalDoneRealtor)?_c('modal-done-realtor',{attrs:{"client-data":_vm.client},on:{"hideModal":_vm.closeModalDoneRealtor,"updateTable":function($event){return _vm.$refs['refGridRealtorCR'].refresh()}}}):_vm._e(),(_vm.activeModalUploadCr)?_c('upload-realtor-cr',{attrs:{"client-data":_vm.client},on:{"close":_vm.closeModalUploadCr,"changeStatusRead":function($event){return _vm.changeStatusRead($event)},"refreshTable":function($event){return _vm.$refs['refGridRealtorCR'].refresh()}}}):_vm._e(),(_vm.workPlanController)?_c('modal-add-work-plan',{attrs:{"id-work-plan":_vm.workPlanData.id_work_plan,"id-ncr":_vm.workPlanData.ncr_id,"client-name":_vm.workPlanData.client_name,"client_id":_vm.workPlanData.id_account,"account":_vm.workPlanData.account},on:{"reload":function($event){return _vm.$refs['refGridRealtorCR'].refresh()},"close":function($event){_vm.workPlanController = false}}}):_vm._e(),(_vm.addRoundModal)?_c('add-round-modal',{attrs:{"data-round":_vm.itemClient,"validate-modal":0},on:{"refresh":function($event){return _vm.$refs['refGridRealtorCR'].refresh()},"close":function($event){_vm.addRoundModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
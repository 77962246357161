<template>
  <b-modal
    size="lg"
    v-model="isVisible"
    modal-class="modal-primary"
    :title="!myData.specialist ? 'Upload CR' : 'View Files'"
    title-tag="h3"
    @hidden="openMe(false)"
    :hide-footer="!(!notSpecialist || (notSpecialist && isCompleted))"
  >
    <account-client-header :account="client.account" :client="client.name" />
    <b-row>
      <b-col cols="12" v-if="!myData.specialist">
        <drag-and-drop
          ref="file-manager"
          v-model="uploadFiles"
          :files-array="uploadFiles"
          :single="true"
        />
      </b-col>
      <b-col cols="12" v-else>
        <b-table
          :items="[myData.specialist]"
          :fields="fieldsTable"
          class="fs-6 mt-1"
          show-empty
          small
        >
          <template #cell(name_file)="data">
            <a :href="data.item.route" target="_blank">
              {{ data.item.name_file }}
            </a>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>

        <span>
          {{ file }}
        </span>
      </b-col>
      <b-col cols="12">
        <h5>Observation of specialist</h5>
        <b-form-textarea
          v-model="recomendation"
          rows="5"
          :disabled="
            (!notSpecialist && (isCompleted || myData.specialist)) ||
            notSpecialist
          "
        />
      </b-col>
    </b-row>
    <br />
    <template #modal-footer>
      <div class="w-100">
        <span class="fs-2 float-left mt-1" v-if="myData.specialist">
          Upload by:
          <b-badge class="px-1" style="font-size: 1rem" variant="info">
            {{ myData.general.name_user_recomendation }}
          </b-badge>
        </span>
        <b-button v-else variant="primary" @click="sendCrRealtor"
          >Upload</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
//components
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
//services
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
//data
import FilesFields from "@/views/commons/components/ncr-realtor/views/data/modal-tracking-fields.js";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
//store
import { mapGetters } from "vuex";

export default {
  components: {
    AccountClientHeader,
    DragAndDrop,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      uploadFiles: [],
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
      file: null,
      myData: {},
      fieldsTable: null,
      obs: "",
      recomendation: "",
    };
  },
  methods: {
    async sendCrRealtor() {
      const parameters = {
        file_name: this.uploadFiles.length > 0 ? this.uploadFiles[0].name : "",
        file_size: this.uploadFiles.length > 0 ? this.uploadFiles[0].size : "",
        file_type: this.uploadFiles.length > 0 ? this.uploadFiles[0].type : "",
        fileapproved:
          this.uploadFiles.length > 0 ? this.uploadFiles[0].fileapproved : "",
        id_account: this.client.account_id,
        idmodul: this.moduleId,
        idrealtor: this.client.realtor_id,
        observation: this.recomendation,
        user_id: this.currentUser.user_id,
      };
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const data = await NcrRealtorService.addRecomendationNcrRealtor({
          ...parameters,
        });
        this.removePreloader();
        if (data) {
          this.showSuccessSwal();
          this.$emit("refreshTable");
          this.openMe(false);
        } else {
          this.showErrorSwal();
        }
      }
    },
    async start() {
      this.fieldsTable = FilesFields;
      const data = await NcrRealtorService.getDataRealtor(
        this.clientData.realtor_id
      );
      this.myData = { ...data };
      this.obs = this.myData.general.obs;
      this.recomendation = this.myData.general.recomendation;
      this.client.name = this.clientData.name;
      this.client.account = this.clientData.account;
      this.client.realtor_id = this.clientData.realtor_id;
      this.client.account_id = this.clientData.account_id;
      this.client.read_status = this.clientData.read_status;
      if (this.client.read_status === 0) {
        this.$emit("changeStatusRead", this.clientData.realtor_id);
      }
    },
    openMe(open) {
      this.isVisible = open;
      if (!open) this.$emit("close");
    },
  },
  computed: {
    notSpecialist() {
      return this.moduleId != 25 ? true : false;
    },
    isCompleted() {
      return this.$route.meta.statusCR ? true : false;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.start();
    this.removePreloader();
    this.openMe(true);
  },
};
</script>
export default [
  {
    key: "client_name",
    label: "Name",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "create_name_user",
    label: "Request by",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "date_completed",
    label: "Completed by",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "files",
    label: "Files",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "auth",
    label: "Auth",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "pdf",
    label: "PDF",
    thClass: "text-center",
    visible: true,
  },
  // {
  //   key: "cr",
  //   label: "CR",
  //   thClass: "text-center",
  //   visible: true,
  // },
  // {
  //   key: "rounds",
  //   label: "Rounds",
  //   thClass: "text-center",
  //   visible: true,
  // },
  // {
  //   key: "work_plan",
  //   label: "Work Plan",
  //   thClass: "text-center",
  //   visible: true,
  // },
  {
    key: "actions",
    label: "Actions",
    thClass: "text-center",
    tdClass: "p-1/2 ",
    visible: true,
  },
];

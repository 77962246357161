import { amgApi } from "@/service/axios";

class NcrRealtorService{

    async getNcrRealtor(parameters){
        try{
            const data = await amgApi.post('/lead/ncr/get-ncr-realtor',parameters)
            return data.data
        }catch(error){
            console.log(error)
        }
    }
    async getUsersProgram(parameters){
        try{
            const data = await amgApi.post('/program/get-program-users',parameters)
            return data.data
        } catch(error){
            console.log(error)
        }
    }
    async searchClient(parameters){
        try{
            const {data} = await amgApi.post('/lead/ncr/search-client-ncr-realtor',parameters)
            if(data[0]){
                const result = {
                    name_client: data[0].name_client,
                    client_account_id: data[0].client_account_id,
                    cards: JSON.parse(data[0].cards),
                    id_lead: data[0].id_lead
                }
                return result
            }else{
                return false
            }
            
        } catch(error){
            console.log(error)
        }
    }
    async createNcrRealtor(parameters){
        try{
            const data = await amgApi.post('/lead/ncr/insert-ncr-realtor',parameters)
            return data
        }catch(error){
            console.log(error)
        }
    }
    async getDataRealtor(parameter){
        try{
            const data = await amgApi.post('lead/ncr/get-data-realtor',{realtorid:parameter})
            const general = {...data.data[0]}
            const advisor = general.data_realtor? [...general.data_realtor] : []
            const specialist = general.data_realtor_recomendation? {...general.data_realtor_recomendation[0]} : null
            return {general,advisor,specialist}
        }catch(error){
            console.log(error)
        }
    }
    async changeStatusRead(parameters){
        try{
            const data = await amgApi.post('lead/ncr/update-status-read-ncr-realtor',parameters)
            return data.status === 200 ? true : false
        }catch(error){
            console.log(error)
        }
    }
    async addRecomendationNcrRealtor(parameters){
        try{
            const data = await amgApi.post('lead/ncr/update-recomendation-ncr-realtor',parameters)
            return data.status === 200 ? true : false
        }catch(error){
            console.log(error)            
        }
    }

    async doneRealtorDigital(parameters){
        try{
            const data = await amgApi.post('lead/ncr/done-realtor-digital',parameters)
            return data.status === 200 ? true : false
        }catch(error){
            console.log(error)            
        }
    }

    async generateBorrowerSignaturePdf(params) {
        try {
            const data = await amgApi.post('/dispute/generate-borrower-signature-auth-pdf', params, {responseType: 'blob'})
            return data
        } catch (error) {
            throw error
        }
    }

    async deleteNcrRealtor(params) {
        try {
            const res = await amgApi.post('/lead/ncr/delete-ncr-realtor', params)
            return res
        } catch (error) {
            throw error
        }
    }

}

export default new NcrRealtorService

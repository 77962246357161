<template>
  <b-modal
    ref="addRoundModal"
    no-close-on-backdrop
    :hide-footer="!showViewDispute && !G_IS_FOOTER_MODAL_ACTIVE"
    title-class="h3 text-white font-weight-bolder"
    modal-class="modal-primary"
    :title="title"
    scrollable
    size="xmd"
    @hidden="closeModal"
  >
    <div>
      <div>
        <b-row class="mb-2">
          <b-col sm="12" md="6" :lg="moduleId == 28 ? 4 : 6">
            <b-input-group class="mt-1">
              <b-input-group-prepend variant="light" style="width: 30%">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  readonly
                  role="text"
                  >ACCOUNT</b-button
                >
              </b-input-group-prepend>

              <b-form-input
                v-model="dataRound.account"
                class="text-center"
                :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                readonly
              />
            </b-input-group>
          </b-col>
          <b-col sm="12" md="6" :lg="moduleId == 28 ? 4 : 6">
            <b-input-group class="mt-1">
              <b-input-group-prepend variant="light" style="width: 30%">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  readonly
                  role="text"
                  >CLIENT</b-button
                >
              </b-input-group-prepend>

              <b-form-input
                v-model="dataRound.clientName"
                class="text-center"
                :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                readonly
              />
            </b-input-group>
          </b-col>
          <template v-if="moduleId == 11 || moduleId == 25 || moduleId == 28">
            <b-col sm="12" md="6" lg="4">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :variant="isDarkSkin ? 'primary' : 'light'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    >DOB</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  :value="dataRound.clientData.dob | myGlobal"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
            <b-col sm="12" md="6" lg="4">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :variant="isDarkSkin ? 'primary' : 'light'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    >{{
                      ssnClient != ""
                        ? "SSN"
                        : itinClient != ""
                        ? "ITIN"
                        : otherClient != ""
                        ? "OTHER"
                        : "SSN"
                    }}</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  v-model="clientDataSsnItinOther"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
            <b-col sm="12" md="6" lg="4">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :variant="isDarkSkin ? 'primary' : 'light'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    >ADDRESS</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  v-model="dataRound.clientData.address"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
            <b-col sm="12" md="6" lg="4">
              <b-input-group class="mt-1">
                <b-input-group-prepend
                  variant="light"
                  style="width: 30%; height: auto"
                >
                  <b-button
                    :variant="isDarkSkin ? 'primary' : 'light'"
                    class="w-100 disableBtn"
                    style="padding: 4px"
                    readonly
                    role="text"
                    >ORIGIN COUNTRY</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  v-model="dataRound.clientData.origin_country"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
          </template>
        </b-row>
      </div>
      <div>
        <div
          v-if="validationOpenTable && !G_CREATE_DETAIL_LETTERS_MODAL"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            @click="openCreateCRRoundModal(null)"
            v-if="validateModal === 0 && !hideAddButton"
          >
            <feather-icon icon="PlusIcon" class="mr-1" />ADD
          </b-button>
        </div>

        <div v-if="validationOpenTable && !G_CREATE_DETAIL_LETTERS_MODAL">
          <b-tabs
            pills
            class="b-pills"
            :class="isDarkSkin ? 'br-nav-dark' : 'br-nav'"
            v-model="tabDispute"
          >
            <b-tab
              :title-link-class="[bgTabsNavs, 'px-3']"
              v-for="(tab, index) in roundTabs"
              :key="index"
            >
              <template #title
                >{{ tab.label }}
                <b-badge variant="danger" pill class="ml-1">
                  {{ index === 0 ? counters.directs : counters.letters }}
                </b-badge>
              </template>
            </b-tab>
          </b-tabs>

          <b-card
            class="border-top-primary border-3 border-table-radius"
            no-body
          >
            <b-table
              ref="roundLetterTable"
              small
              responsive
              :fields="visibleFields"
              :busy.sync="isBusy"
              :items="tableItems"
              class="font-small-3"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>

              <template v-slot:cell(type_method)="data">
                <span v-if="[3, 4, 5].includes(data.item.type_round)"
                  >DIRECT</span
                >
                <span v-else>LETTER</span>
              </template>

              <template v-slot:cell(round)="data">
                <span
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  style="font-weight: bold"
                >
                  {{ data.item.title_r ? data.item.title_r : "---" }}
                </span>
              </template>

              <template v-slot:cell(bureaus)="data">
                <span v-if="data.item.sumtr > 0"
                  >TUR ({{ data.item.sumtr }}) |</span
                >
                <span v-if="data.item.sumex > 0"
                  >EXP ({{ data.item.sumex }}) |</span
                >
                <span v-if="data.item.sumeq > 0"
                  >EQR ({{ data.item.sumeq }})</span
                >
                <feather-icon
                  v-if="data.item.sumtr + data.item.sumex + data.item.sumeq > 0"
                  icon="EyeIcon"
                  class="cursor-pointer ml-1 text-info"
                  size="18"
                  @click="
                    detailround(
                      data.item.id,
                      data.item.format_r,
                      data.item.title_r,
                      [3, 4, 5].includes(data.item.type_round),
                      data.item.status,
                      data.item.created_at
                    )
                  "
                />
                <span v-else>---</span>
              </template>
              <template v-slot:cell(format)="data">
                {{ data.item.format_r ? data.item.format_r : "---" }}
              </template>

              <template v-slot:cell(status)="data">
                <div
                  v-if="
                    validateModal === 1 && [1, 2].includes(data.item.type_round)
                  "
                >
                  <b-badge
                    v-if="[3, 4, 6, 8].includes(data.item.state)"
                    variant="light-warning"
                    >{{ data.item.status }}</b-badge
                  >

                  <b-badge
                    v-if="[2, 5, 1].includes(data.item.state)"
                    variant="light-primary"
                    >{{ data.item.status }}</b-badge
                  >
                  <b-badge
                    v-if="[7].includes(data.item.state)"
                    variant="light-success"
                    >{{ data.item.status }}</b-badge
                  >
                  <feather-icon
                    v-if="data.item.state === 3"
                    icon="NavigationIcon"
                    size="15"
                    class="cursor-pointer text-success"
                    @click="
                      openModalProcess(
                        data.item.id,
                        0,
                        data.item.sumtr,
                        data.item.sumex,
                        data.item.sumeq
                      )
                    "
                  />
                </div>
                <div
                  v-if="
                    validateModal === 1 && [4, 5].includes(data.item.type_round)
                  "
                >
                  <b-badge
                    :variant="
                      data.item.state === 1 ? 'light-warning' : 'light-success'
                    "
                  >
                    {{ data.item.state === 1 ? "PENDING" : "COMPLETE" }}
                  </b-badge>
                </div>
              </template>
              <template v-slot:cell(tracking)="data">
                <div v-if="validateModal === 1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    @click="alltracking(data.item.id)"
                  />
                </div>
              </template>

              <template v-slot:cell(created_by)="data">
                <span>{{ data.item.created_by }}</span
                ><br /><span>{{ data.item.created_at | myGlobalDay }}</span>
              </template>
              <template v-slot:cell(updated_by)="data">
                <span> {{ data.item.updated_by }}</span
                ><br /><span v-if="data.item.updated_at != null">{{
                  data.item.updated_at | myGlobalDay
                }}</span>
              </template>

              <template v-slot:cell(actions)="data">
                <feather-icon
                  icon="EditIcon"
                  size="17"
                  class="text-warning cursor-pointer mr-1"
                  @click="openCreateCRRoundModal(data.item)"
                /><feather-icon
                  icon="TrashIcon"
                  size="17"
                  class="text-danger cursor-pointer"
                  @click="deleteCRRound(data.item.id)"
                />
                <feather-icon
                  icon="PlusIcon"
                  size="17"
                  class="text-primary cursor-pointer ml-1"
                  @click="openCreateDetailLetterModal(data.item)"
                />
              </template>

              <template v-slot:cell(read)="data">
                <span v-if="data.item.read === 1" class="text-danger">NO</span
                ><span v-if="data.item.read === 0" class="text-success"
                  >YES</span
                >
              </template>
              <template v-slot:cell(dispute_results)="data">
                <div
                  v-if="[5, 6, 22, 7, 11, 25, 20, 28].includes(moduleId)"
                  class="d-flex justify-content-center"
                >
                  <feather-icon
                    v-if="
                      data.item.state == 7 &&
                      data.item.status_dispute === 0 &&
                      (([4, 5].includes(data.item.type_round) &&
                        data.item.date_diff > 34) ||
                        data.item.date_diff > 44)
                    "
                    icon="PlusIcon"
                    size="18"
                    class="cursor-pointer text-primary"
                    @click="openModalDispute(data.item)"
                  />
                  <b-button
                    v-else-if="
                      data.item.state === 7 &&
                      data.item.status_dispute === 1 &&
                      data.item.status_dispute_grilla == 'PENDING' &&
                      (([4, 5].includes(data.item.type_round) &&
                        data.item.date_diff > 34) ||
                        data.item.date_diff > 44)
                    "
                    variant="warning"
                    @click="openModalViewDispute(data.item.dispute_id)"
                    >{{ data.item.status_dispute_grilla }}</b-button
                  >
                  <b-button
                    v-else-if="
                      data.item.state === 7 &&
                      data.item.status_dispute_grilla == 'COMPLETED' &&
                      (([4, 5].includes(data.item.type_round) &&
                        data.item.date_diff > 34) ||
                        data.item.date_diff > 44)
                    "
                    style="
                      cursor: pointer;
                      color: rgb(0, 204, 0);
                      text-transform: uppercase;
                      padding: 8px;
                      background: rgb(241, 241, 241);
                      font-weight: bold;
                    "
                    @click="openModalViewDispute(data.item.dispute_id)"
                    >{{ data.item.status_dispute_grilla }}</b-button
                  >
                  <b-button
                    v-else-if="
                      [11, 25, 28].includes(moduleId) &&
                      data.item.dispute_id != null
                    "
                    variant="success"
                    @click="
                      openModalViewDispute(data.item.id, data.item.dispute_id)
                    "
                    >SHOW</b-button
                  >
                </div>
              </template>
            </b-table>
          </b-card>
        </div>

        <tracking-modal-ro
          v-if="trackingModalOn"
          :data="trackingData"
          @close="closeTrackingModalRo"
        />
        <create-dispute
          v-if="modalDisputeOn"
          :data="createDisputeData"
          @close="closeModalDispute"
        />
        <details-component2
          v-if="detailsModalOn"
          :data-round="detailRoundData"
          :client-data="dataRound"
          :validate-state-created="true"
          :validate-modal="validateModal"
          :show-all-letters="showAllLetters"
          @viewpdf="showViewPdf"
          @close="closeDetailsModal"
        />
        <show-letters-by-bureau
          v-if="showLetterByBureau"
          :bureaus="bureaus"
          :data-round="detailRoundData"
          @close="closeShowViewPdf"
        />
        <view-dispute-component-new
          v-if="showViewDispute"
          :dispute="dispute"
          :data-dispute="dataDispute"
          @close="closeModalViewDispute"
        />
        <process-component
          v-if="showProcessModal"
          :idround="processData.id"
          :ittra="processData.sumTr"
          :itexp="processData.sumEx"
          :itequ="processData.sumEq"
          :typestate="processData.number"
          @close="closeShowProcessModal"
        />
        <create-c-r-round-modal
          v-if="createCRRoundModal"
          :ncr-id="dataRound.id"
          :round-letter-data="roundLetterData"
          :editing="editingRound"
          :hasWorkPlan="dataRound.hasWorkPlanCreated"
          :clientAccountId="dataRound.idAccount"
          :idWorkplan="dataRound.idWorkplan"
          :createdRounds="items"
          @close="closeCreateCRRoundModal"
        />
      </div>
    </div>
    <template #modal-footer>
      <div v-if="!G_IS_FOOTER_MODAL_ACTIVE">
        <div>DONE BY:</div>
        <div class="font-weight-bolder">
          {{ dispute.name_user_recomendation }} |
          {{ dispute.created_recommendation | myGlobalDay }}
        </div>
      </div>
      <div v-if="G_IS_FOOTER_MODAL_ACTIVE">
        <b-button variant="success" @click="editDelAccountInUni">
          <feather-icon icon="SaveIcon" />
          SAVE</b-button
        >
      </div>
    </template>

    <create-detail-letters-modal
      v-if="G_CREATE_DETAIL_LETTERS_MODAL && G_REDIRECT_FROM_TABLE"
      :round-data="dataRound"
      :sender-data="senderData"
      @close="closeCreateDetailLetterModal"
    />
  </b-modal>
</template>
<script>
import dataFields from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/fields.data";

import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapActions, mapGetters } from "vuex";
import TrackingModalRo from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/tracking-modal-ro/TrackingModalRo.vue";
import CreateDispute from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/CreateDispute.vue";
import DetailsComponent from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsComponent.vue";
import DetailsComponent2 from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsComponent2.vue";
import ShowLettersByBureau from "@/views/commons/components/clients/dashboard/options/dis-module/modals/show-letters-by-bureu/ShowLettersByBureau.vue";
import ViewDisputeComponentNew from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/view-dispute-modal/ViewDisputeComponentNew.vue";
import ProcessComponent from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/ProcessComponent.vue";
import CreateCRRoundModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/create-cr-round/CreateCRRoundModal.vue";
import CreateDetailLettersModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/create-detail-letters-modal/CreateDetailLettersModal.vue";

import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import moment from "moment";

export default {
  components: {
    TrackingModalRo,
    CreateDispute,
    DetailsComponent,
    ShowLettersByBureau,
    ViewDisputeComponentNew,
    DetailsComponent2,
    ProcessComponent,
    CreateCRRoundModal,
    CreateDetailLettersModal,
  },
  props: {
    dataRound: {
      type: Object,
      default: null,
    },
    validateModal: {
      type: Number,
      default: null,
    },
    showAllLetters: {
      type: Boolean,
      default: true,
    },
    fromWpTable: {
      type: Boolean,
      default: false,
    },
    hideAddButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      detailsModalOn: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      comments: null,
      fields: dataFields,
      items: [],
      trackingModalOn: false,
      trackingData: {},
      modalDisputeOn: false,
      dispute: {},
      createDisputeData: {},
      dataDispute: {},
      titleRound: "",
      detailRoundData: {},
      showLetterByBureau: false,
      showViewDispute: false,
      showProcessModal: false,
      processData: {},
      createCRRoundModal: false,
      roundLetterData: { id: null },
      footerSave: false,
      editingRound: false,
      clientDob: "",
      ssnClient: "",
      origin_country: "",
      addressClient: "",
      itinClient: "",
      otherClient: "",
      clientDataSsnItinOther: "",
      totalRounds: null,
      senderData: {},
      bureaus: [],
      roundTabs: [
        {
          id: 1,
          label: "DIRECTS",
        },
        {
          id: 2,
          label: "LETTERS",
        },
      ],
      isBusy: false,
      tabDispute: 0,
    };
  },
  computed: {
    counters() {
      return this.items.reduce(
        (acc, item) => {
          if ([3, 4, 5].includes(item.type_round)) {
            acc.directs += 1;
          } else {
            acc.letters += 1;
          }
          return acc;
        },
        { directs: 0, letters: 0 }
      );
    },
    roundTypes() {
      return this.tabDispute == 1 ? [1, 2] : [3, 4, 5];
    },
    tableItems() {
      return (
        this.items.filter((item) =>
          this.roundTypes.includes(item.type_round)
        ) || []
      );
    },
    validationOpenTable() {
      if (this.modalDisputeOn === true) {
        return false;
      }
      if (this.trackingModalOn === true) {
        return false;
      }
      if (this.detailsModalOn === true) {
        return false;
      }
      if (this.showLetterByBureau === true) {
        return false;
      }
      if (this.showViewDispute === true) {
        return false;
      }
      if (this.showProcessModal === true) {
        return false;
      }
      return this.createCRRoundModal !== true;
    },
    title() {
      if (this.modalDisputeOn === true) {
        return "UPDATE REQUEST";
      }
      if (this.trackingModalOn === true) {
        return "TRACKING";
      }
      if (this.detailsModalOn === true) {
        return this.detailRoundData.title;
      }
      if (this.createCRRoundModal === true) {
        if (this.editingRound === true) {
          return "EDIT DISPUTE LETTER";
        }
        return "CREATE DISPUTE";
      }
      return "DISPUTES";
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_FOOTER_MODAL_ACTIVE:
        "SpecialistsDigitalRoundLettersStore/G_IS_FOOTER_MODAL_ACTIVE",
      G_BUREAU_LETTERS_DATA:
        "SpecialistsDigitalRoundLettersStore/G_BUREAU_LETTERS_DATA",
      G_CREATE_DETAIL_LETTERS_MODAL:
        "SpecialistsDigitalRoundLettersStore/G_CREATE_DETAIL_LETTERS_MODAL",
      G_REDIRECT_FROM_TABLE:
        "SpecialistsDigitalRoundLettersStore/G_REDIRECT_FROM_TABLE",
      getRefreshTable: "SpecialistsDigitalRoundLettersStore/getRefreshTable",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.filterColumn("type_method", false);
    this.filterColumn("round", false);
    this.filterColumn("format", false);
    this.items = await this.search();
    console.log("lia")
  },
  mounted() {
    this.toggleModal("addRoundModal");
    if (this.validateModal === 1) {
      this.filterColumn("status", true);
    }
    if (this.validateModal === 1) {
      this.filterColumn("tracking", true);
    }
    if (this.validateModal === 0 || this.validateModal === 3) {
      this.filterColumn("updated_by", true);
    }
    if (this.validateModal === 0 || this.validateModal === 3) {
      this.filterColumn("actions", true);
    }
    if ([5, 6, 22, 7, 11, 25, 20, 28].includes(this.moduleId)) {
      this.filterColumn("dispute_results", true);
    }

    this.clientDob = this.dataRound.clientData.dob
      ? moment(this.dataRound.clientData.dob).format("MM/DD/YYYY")
      : "";
    this.ssnClient = this.dataRound.clientData.ssn
      ? this.dataRound.clientData.ssn
      : "";
    this.itinClient = this.dataRound.clientData.itin
      ? this.dataRound.clientData.itin
      : "";
    this.otherClient = this.dataRound.clientData.other
      ? this.dataRound.clientData.other
      : "";
    this.origin_country = this.dataRound.clientData.origin_country
      ? this.dataRound.clientData.origin_country
      : "";
    this.addressClient = this.dataRound.clientData.address
      ? this.dataRound.clientData.address
      : "";
    this.clientDataSsnItinOther = this.ssnClient
      ? this.ssnClient
      : this.itinClient
      ? this.itinClient
      : this.otherClient
      ? this.otherClient
      : "";
    if (this.dataRound.isDirect) {
      const { id, title, format } = this.dataRound.isDirect;
      this.detailround(id, format, title, false, this.dataRound.status_round, this.dataRound.round_created_at);
    }
  },
  methods: {
    ...mapActions({
      A_EDIT_LETTER_MODAL:
        "SpecialistsDigitalRoundLettersStore/A_EDIT_LETTER_MODAL",
      A_IS_FOOTER_MODAL_ACTIVE:
        "SpecialistsDigitalRoundLettersStore/A_IS_FOOTER_MODAL_ACTIVE",
      REFRESH_WORKPLAN_TABLE: "RequestWorkPlanStore/CHANGE_REFRESH_TABLE",
      A_CREATE_DETAIL_LETTERS_MODAL:
        "SpecialistsDigitalRoundLettersStore/A_CREATE_DETAIL_LETTERS_MODAL",
      A_REDIRECT_FROM_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_REDIRECT_FROM_TABLE",
      REFRESH_DISPUTE_TABLE:
        "SpecialistsDigitalRoundLettersStore/CHANGE_REFRESH_TABLE",
    }),
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    openCreateDetailLetterModal(item) {
      this.senderData = {
        format: item.format_r,
        title: item.title_r,
        id: item.id,
        isDirect: Boolean(this.dataRound.isDirect),
      };
      this.A_CREATE_DETAIL_LETTERS_MODAL(true);
      this.A_REDIRECT_FROM_TABLE(true);
    },
    closeCreateDetailLetterModal() {
      this.A_CREATE_DETAIL_LETTERS_MODAL(false);
      this.A_REDIRECT_FROM_TABLE(false);
    },
    showViewPdf(arrBureaus) {
      this.bureaus = arrBureaus;
      this.closeDetailsModal();
      this.showLetterByBureau = true;
    },
    closeShowViewPdf() {
      this.detailsModalOn = true;
      this.showLetterByBureau = false;
    },
    closeDetailsModal() {
      this.detailsModalOn = false;
    },

    async openModalViewDispute(id) {
      this.dataDispute = { idDispute: id };
      this.showViewDispute = true;
      await this.getAllDataLettersDispute(id);
      await this.updateCountId(id);
    },
    async getAllDataLettersDispute(id) {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.allDataLettersDispute({
          id,
        });
        if (data.status === 200) {
          this.dispute = data.data[0];
          this.isBusy = false;
        }
      } catch (e) {
        this.removePreloader();
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async updateCountId(id) {
      try {
        await DisputeResultsService.updateCountDisputeId({ iddispute: id });
        await this.$store.dispatch(
          "DebtSolutionClients/A_UPDATE_EVALUATED_BY_SP",
          {
            clientAccountId: this.$route.params.idClient,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    closeModalViewDispute() {
      this.showViewDispute = false;
    },
    closeShowProcessModal() {
      this.showProcessModal = false;
    },
    openModalDispute(data) {
      this.createDisputeData = { id: data.id, client_account_id: this.dataRound.idAccount };
      this.modalDisputeOn = true;
    },
    closeModalDispute() {
      this.modalDisputeOn = false;
    },
    async deleteCRRound(item) {
      const confirmed = await this.showConfirmSwal();
      if (confirmed.isConfirmed) {
        try {
          const params = {
            id: item,
            has_workplan: this.dataRound.hasWorkPlanCreated,
            is_last_round: this.totalRounds === 1,
            client_account: this.dataRound.idAccount,
          };
          const response = await ClientsOptionsServices.deleteRoundLetter(
            params
          );
          if (response.status === 200) {
            this.REFRESH_DISPUTE_TABLE(true);
            if (this.fromWpTable) {
              this.REFRESH_WORKPLAN_TABLE(true);
            }
            this.$emit("refresh");
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    alltracking(id) {
      this.trackingModalOn = true;
      this.trackingData = { id };
    },
    closeTrackingModalRo() {
      this.trackingModalOn = false;
    },
    openModalProcess(id, clientAccountId, sumTr, sumEx, sumEq) {
      this.processData = {
        id,
        clientAccountId,
        sumTr,
        sumEx,
        sumEq,
        number: 8,
      };
      this.showProcessModal = true;
    },
    detailround(id, format, title, isDirect, status = null, created_at = null) {
      // ********************************
      this.detailRoundData = {
        id,
        format,
        title,
        isDirect,
        status_round: status,
        round_created_at: created_at,
      };
      this.detailsModalOn = true;
    },
    closeModal() {
      this.A_EDIT_LETTER_MODAL(false);
      this.A_CREATE_DETAIL_LETTERS_MODAL(false);
      this.$emit("close");
      this.$emit("reload");
    },

    async search() {
      try {
        const params = {
          id: this.dataRound.id,
          idNrl: this.dataRound.idNrl ? this.dataRound.idNrl : "",
          idWorkplan: this.dataRound.idWorkplan,
          onlyRound: typeof this.dataRound.onlyRound == "undefined" ? false : this.dataRound.onlyRound,
        };
        const data = await ClientsOptionsServices.searchRoundLetters(params);
        this.totalRounds = data.data.length;
        const result = data.data;

        // Must return an array of items or an empty array if an error occurred
        if (result.length > 0 && !this.showAllLetters) {
          // This case applies when you are in Direct or Letter Dispute and needs to jump into
          this.detailround(
            this.dataRound.isDirect.id,
            result[result.length - 1].format_r,
            result[result.length - 1].title_r,
            false,
            this.dataRound.status_round,
            this.dataRound.round_created_at
          );
          return;
        }
        return result;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    openCreateCRRoundModal(item = null) {
      this.createCRRoundModal = true;
      this.roundLetterData = item;
      this.editingRound = item !== null;
      this.footerSave = true;
    },
    closeCreateCRRoundModal(data) {
      this.createCRRoundModal = false;
      this.footerSave = false;
      if (!this.editingRound && data !== null) {
        this.detailRoundData = {
          id: data.id,
          format: data.format_r,
          title: data.title_r,
        };
        this.detailsModalOn = true;
      }
      this.$emit("refresh");
    },
    async editDelAccountInUni() {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          if (this.G_BUREAU_LETTERS_DATA.letterTable === 1) {
            const params = {
              content: this.G_BUREAU_LETTERS_DATA.content,
              id: this.G_BUREAU_LETTERS_DATA.id,
              iduser: this.currentUser.user_id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
              status: this.G_BUREAU_LETTERS_DATA.status,
            };
            const response =
              await ClientsOptionsServices.editPersonalInformation(params);
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          } else if (this.G_BUREAU_LETTERS_DATA.letterTable === 2) {
            const params = {
              iduser: this.currentUser.user_id,
              id: this.G_BUREAU_LETTERS_DATA.id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
            };
            const response = await ClientsOptionsServices.editDelAccountInUni(
              params
            );
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          } else if (this.G_BUREAU_LETTERS_DATA.letterTable === 3) {
            const params = {
              iduser: this.currentUser.user_id,
              id: this.G_BUREAU_LETTERS_DATA.id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
            };
            const response = await ClientsOptionsServices.editDelAccountPrUni(
              params
            );
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          } else if (this.G_BUREAU_LETTERS_DATA.letterTable === 4) {
            const params = {
              accountN: this.G_BUREAU_LETTERS_DATA.accountN,
              accountName_id: this.G_BUREAU_LETTERS_DATA.accountName_id,
              balance: this.G_BUREAU_LETTERS_DATA.balance,
              id: this.G_BUREAU_LETTERS_DATA.id,
              iduser: this.currentUser.user_id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
              status: this.G_BUREAU_LETTERS_DATA.status,
              type: this.G_BUREAU_LETTERS_DATA.type,
            };
            const response = await ClientsOptionsServices.editAccount(params);
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    tabDispute(value) {
      this.filterColumn("round", value == 1); // directs
      this.filterColumn("format", value == 1);
    },
    async getRefreshTable(value) {
      if (value) {
        this.isBusy = true;
        this.items = await this.search();
        this.isBusy = false;
        this.REFRESH_DISPUTE_TABLE(false);
      }
    },
  },
};
</script>

<style>
.b-pills .nav-pills {
  margin-bottom: 0 !important;
}
</style>

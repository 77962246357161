<template>
  <div>
    <b-modal
      ref="modal-done"
      modal
      centered
      size="lg"
      header-class="p-0  border-bottom-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="CR Realtor"
      title-class="h2 text-white"
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalHeader
          :data-client="clientData"
          :title="'CR REALTOR'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-row>
          <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
            <h6 class="text-white text-center">OBSERVATION</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-textarea
              id="textarea"
              v-model="obs"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button @click="hideModal"> Cancel </b-button>
        <b-button variant="primary" @click="onSubmit"> Save </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
import ModalHeader from "@/views/specialist-digital/views/process-cr/view/components/modals/ModalHeader.vue";

export default {
  components: {
    ModalHeader,
    ValidationObserver,
  },
  props: {
    clientData: {
      type: Object,
    },
  },
  data() {
    return {
      obs: "",
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.start();
    this.removePreloader();
  },
  mounted() {
    this.toggleModal("modal-done");
  },
  methods: {
    async onSubmit(item) {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        try {
          const parameters = {
            id_account: this.client.account_id,
            idrealtor: this.client.realtor_id,
            observation: this.obs,
            user_id: this.currentUser.user_id,
            lead_id: this.client.lead_id,
            ncr_id: this.client.id

          };
          await NcrRealtorService.doneRealtorDigital(parameters);
          this.$emit("updateTable");
          this.hideModal();
          this.removePreloader();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "The satisfying action."
          );
        } catch (error) {
          console.log(error);
        }
      }
    },
    async start() {
      this.client = Object.assign({}, this.clientData);
      const data = await NcrRealtorService.getDataRealtor(
        this.clientData.realtor_id
      );

      this.myData = { ...data };
      this.obs = this.myData.general.recomendation;
      this.client.name = this.clientData.name;
      this.client.realtor_id = this.clientData.realtor_id;
    },
    hideModal() {
      this.$refs['modal-done'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
  },
};
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>
